import React, { Suspense } from 'react'
import { Canvas } from '@react-three/fiber'
import { ContactShadows } from '@react-three/drei'
import Model from './Model'

export default function App() {
  return (
    <>
      <Canvas camera={{ position: [6, 3, 2], fov: 30 }} dpr={[1, 2]}>
        <pointLight position={[-10, 0, 0]} intensity={0.99} color="orange" />
        <pointLight position={[10, 10, 0]} intensity={0.8} color="white" />
        <hemisphereLight
          color="white"
          groundColor="white"
          position={[-7, 25, 13]}
          intensity={0.99}
        />

        <Suspense fallback={null}>
          <group position={[0, 0, 0]}>
            <Model />
            <ContactShadows
              rotation-x={Math.PI / 2}
              position={[0, 0, 0]}
              opacity={0.25}
              width={100}
              height={100}
              blur={2}
              far={50}
            />
          </group>
        </Suspense>
      </Canvas>
      <div class="logo"></div>
    </>
  )
}
