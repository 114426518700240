import * as THREE from 'three'
import { useEffect, useState } from 'react'
import { useThree, useFrame } from '@react-three/fiber'
import { useGLTF } from '@react-three/drei'
import { GLTFLoader } from 'three-stdlib'

const gltfFile = '/Cardan-Joint-blender-8-MaterialArms.gltf'

export default function Model() {
  const three = useThree()
  const [model, setModel] = useState()
  const [mixer, setMixer] = useState()

  useEffect(() => {
    const loader = new GLTFLoader()

    loader.load(
      gltfFile,
      function (gltf) {
        const model = gltf.scene
        model.position.set(0, 0, 0)
        three.scene.add(model)

        const mixer = new THREE.AnimationMixer(model)
        mixer.clipAction(gltf.animations[0]).play()

        setMixer(mixer)
        setModel(model)
      },
      undefined,
      function (e) {
        console.error(e)
      }
    )
  }, [three.scene])

  useFrame((state, delta) => {
    if (mixer) {
      mixer.update(delta)
    }

    if (model) {
      model.rotation.y = THREE.MathUtils.lerp(model.rotation.y, (state.mouse.x * Math.PI) / 10, 0.05)
      model.rotation.x = THREE.MathUtils.lerp(model.rotation.x, (state.mouse.y * Math.PI) / 15, 0.05)
    }
  })

  return null
}

useGLTF.preload(gltfFile)
